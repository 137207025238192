import { HashMap } from '@zerops/zef/core';

export interface RemoteLoggingDataPayload {
  certificate?: string;
  syslogConfig: string;
}

export interface VpnPublicKeyPayload {
  publicKey: string;
}

export interface VpnProjectSetting {
  publicKey: string;
  ipv4: {
    network: {
      network: string;
      gateway: string;
    };
    sharedEndpoint: string;
    endpoint: string;
  };
  ipv6: {
    network: {
      network: string;
      gateway: string;
    };
    sharedEndpoint: string;
    endpoint: string;
  };
}

export interface VpnPeerSetting {
  publicKey: string;
  ipv4: {
    network: {
      network: string;
      gateway: string;
    };
    assignedIpAddress: string;
  };
  ipv6: {
    network: {
      network: string;
      gateway: string;
    };
    assignedIpAddress: string;
  };
  setup: string; // Generic setup
  setupWindows: string; // Setup for MS Windows
  setupMacOS: string; // Setup for Apple MacOS
  setupLinux: string; // Setup for Linux
}

/**
 * It defines the response data structure of a request registering a WireGuard
 * VPN public key for a project </api/rest/public/projectId/vpn>.
 */
export interface VpnSetting {
  project: VpnProjectSetting;
  peer: VpnPeerSetting;
}

/**
 * It defines the response data structure of a request listing WireGuard
 * VPN public keys for a project </api/rest/public/projectId/vpn/list>.
 */
export interface VpnSettings {
  project: VpnProjectSetting;
  peers: Array<VpnPeerSetting>;
}

export class ProjectBaseState {
  tags: string[];
  vpnSettingsMap: HashMap<VpnSettings>;

  constructor() {
    this.tags = undefined;
    this.vpnSettingsMap = {};
  }
}
