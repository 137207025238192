/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './notification-item.constant';

const en = {
  markAsReadTooltip: `Mark as read`,
  zeropsYamlErrors: {
    zeropsBuildFileNotFound: '<a data-zui-docs="build/build-config.html">zerops.yml</a> file not found at the root of your project',
    yamlValidationInvalidYaml: `Pipeline couldn't be started due to invalid <a data-zui-docs="build/build-config.html">zerops.yml</a>`,
    zeropsYamlSetupNotFound: `Setup not found in zerops.yml`,
    zeropsYamlServiceNotFound: 'The service was not found in zerops.yml.',
    zeropsYamlInvalidYaml: 'zerops.yml file invalid, see meta for more information.',
    zeropsYamlTooManyExtends: 'zerops.yml has too many extends.',
    zeropsYamlInvalidParameter: 'Invalid parameter provided in zerops.yml, see meta for more information.',
    zeropsYamlMissingParameter: 'Mandatory parameter is missing in zerops.yml see meta for more information.',
    zeropsYamlDocumentRootRequired: 'Parameter "run.documentRoot" is required for this type of service.',
    zeropsYamlStartCommandRequired: 'Parameter "run.start" is required for this type of service',
    zeropsYamlNoProbeSpecified: 'No probe was specified, there has to be exactly one.',
    zeropsYamlTooManyProbes: 'Too many probes specified, there has to be exactly one.',
    zeropsBuildFileTooLarge: 'Build file too large, over 10kB',
    zeropsYamlRunOsRequired: 'Parameter "run.os" is required.',
    zeropsYamlRunBaseRequired: 'Parameter "run.base" is required.',
    zeropsYamlRunOsBaseInvalid: 'Invalid run.os and run.base combination.',
    internalServerError: `Internal server error, please contact support if errors repeats`
  },
  'stack_start_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}">
  <strong>{serviceName}</strong></a> was successfully <strong>started</strong>`,
  'stack_start_WARNING': `Could not start {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}
  </strong></a>, process ended with <strong>warning</strong>`,
  'stack_start_ERROR': `Failed to start {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}">
  <strong>{serviceName}</strong></a>`,
  'stack_stop_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}">
  <strong>{serviceName}</strong></a> was successfully <strong>stopped</strong>`,
  'stack_stop_WARNING': `Could not stop {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>, process ended with <strong>warning</strong>`,
  'stack_stop_ERROR': `Failed to stop {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}">
  <strong>{serviceName}</strong></a>`,
  'runtime_stack_create_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong>
  </a>&nbsp;<strong>added</strong>`,
  'stack_create_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong>
  </a>&nbsp;<strong>added</strong> and <a data-zui-docs="deploy/how-deploy-works.html">Ready to deploy</a>`,
  'stack_create_WARNING': `Add {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>&nbsp;<strong>
  ended with warning</strong>`,
  'stack_create_ERROR': `Failed to create {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}">
  <strong>{serviceName}</strong></a>`,
  'stack_updateAutoscaling_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}">
  <strong>{serviceName}</strong></a> autoscaling was successfully <strong>updated</strong>`,
  'stack_updateAutoscaling_WARNING': `Could not update {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}
  </strong></a> autoscaling, process ended with <strong>warning</strong>`,
  'stack_updateAutoscaling_ERROR': `Failed to update {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  /<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}">
  <strong>{serviceName}</strong></a> autoscaling`,
  'instance_create_SUCCESS': `Project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}
  </a>&nbsp;core service activated
  <span class="__desc">The core service will take care of
  secure data communication between the Internet and your project
  and of storage for application logs and technical statistics.</span>`,
  'instance_create_ERROR': `Failed to initialize project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;and add its core service`,
  'instance_delete_SUCCESS': `Project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;was successfully deleted`,
  'instance_delete_ERROR': `Failed to delete project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;and its core service`,
  'instance_delete_WARNING': `Could not delete project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;and its core service`,
  'instance_stop_SUCCESS': `Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">
  {projectName}</a>&nbsp;was successfully stopped`,
  'instance_stop_ERROR': `Failed to stop project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">
  {projectName}</a>&nbsp;and its core service`,
  'instance_start_SUCCESS': `Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">
  {projectName}</a>&nbsp;was successfully started`,
  'instance_start_ERROR': `Failed to start project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;and its core service`,
  'instance_startPrivate_SUCCESS': `Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">
  {projectName}</a>&nbsp;was successfully started`,
  'instance_startPrivate_ERROR': `Failed to start project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;and its core service`,
  'instance_syncPublicHttpRoutingAndCreateL7HttpBalancerStack_SUCCESS': `Project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  HTTP balancer <strong>added</strong> and public access through domains <strong>synchronized</strong>`,
  'instance_syncPublicHttpRouting_SUCCESS': `Project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>&nbsp;
  public access through domains <strong>synchronized</strong>`,
  'instance_syncPublicPortRouting_SUCCESS': `Project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  &nbsp;public Access through IP addresses <strong>synchronized</strong>`,
  'instance_syncPublicHttpRouting_ERROR': `Failed to synchronize project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  &nbsp;public access through domains`,
  'instance_syncPublicPortRouting_ERROR': `Failed to synchronize project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>
  &nbsp;public access through IP addresses`,
  'instance_createL7HttpBalancerStack_SUCCESS': `Project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>&nbsp;HTTP balancer <strong>
  created</strong> <span class="__desc">HTTP balancer handles
  the HTTP/S data traffic from the Internet to your applications: Handles SSL certificates and load
  balances any HTTP/S traffic, whether it's through the Zerops subdomain or your own domains.</span>`,
  'instance_createL7HttpBalancerStack_ERROR': `Failed to create project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>&nbsp;HTTP balancer <span class="__desc">HTTP balancer handles
  the HTTP/S data traffic from the Internet to your applications: Handles SSL certificates and load
  balances any HTTP/S traffic, whether it's through the Zerops subdomain or your own domains.</span>`,
  'instance_deleteL7HttpBalancerStack_SUCCESS': `Project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>&nbsp;HTTP balancer <strong>deleted</strong>`,
  'instance_deleteL7HttpBalancerStack_ERROR': `Failed to delete project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>&nbsp;HTTP balancer`,
  'instanceGroup_create_SUCCESS': `Project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>&nbsp;<strong>
  initialized</strong> and waiting for first service`,
  'instance_stopPrivate_SUCCESS': `Project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>&nbsp;was stopped`,
  'instanceGroup_create_ERROR': `Failed to initialize project
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'instanceGroup_delete_SUCCESS': `Project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;<strong>deleted</strong>`,
  'stack_restart_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;was successfully <strong>restarted</strong>`,
  'stack_restart_WARNING': `Could not restart {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>, process ended with warning`,
  'stack_restart_ERROR': `Failed to restart {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>`,
  'stack_reload_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;<strong>reloaded</strong>`,
  'stack_reload_WARNING': `Could not reload {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;<strong>, process ended with warning</strong>`,
  'stack_reload_ERROR': `Failed to reload {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>`,
  'stack_deploy_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;<strong>deployed</strong>`,
  'stack_deploy_WARNING': `Could not deploy {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;<strong>, process ended with warning</strong>`,
  'stack_deploy_ERROR': `Failed to deploy {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>`,
  'stack_delete_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;<strong>deleted</strong>`,
  'stack_delete_WARNING': `Could not delete {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;<strong>, process ended with warning</strong>`,
  'stack_delete_ERROR': `Failed to delete {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>`,
  'stack_updateUserData_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a> variables <strong>committed</strong> to the server
  <span class="__desc">Restart is required for changes to apply.</span>`,
  'stack_updateUserData_WARNING': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a> service variables commit to the server ended with warning`,
  'stack_updateUserData_ERROR': `Failed to commit {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a> variables to the server`,
  'stack_disableSubdomainAccess_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain access <strong>disabled</strong>`,
  'stack_disableSubdomainAccess_WARNING': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain access disable <strong>ended with warning</strong>`,
  'stack_disableSubdomainAccess_ERROR': `Failed to disable {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain access`,
  'stack_enableSubdomainAccess_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain access <strong>enabled</strong>`,
  'stack_enableSubdomainAccess_WARNING': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain access enable <strong>ended with warning</strong>`,
  'stack_enableSubdomainAccess_ERROR': `Failed to enable {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain access`,
  'stack_update_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;successfully updated`,
  'stack_update_ERROR': `Failed to update {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>`,
  'stack_updatePorts_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;internal ports successfully updated`,
  'stack_updatePorts_ERROR': `Failed to update {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;internal ports`,
  'stack_updatePortsSubdomainAccess_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain ports successfully updated`,
  'stack_updatePortsSubdomainAccess_ERROR': `Failed to update {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain ports`,
  'stack_updatePortsDisableSubdomainAccess_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain ports successfully updated, subdomain was disabled`,
  'stack_updatePortsDisableSubdomainAccess_ERROR': `Failed to update {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;subdomain ports and disable subdomain`,
  'stack_sharedStorageConnect_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;shared storage <strong>connected</strong>`,
  'stack_nginxConfig_SUCCESS': `Nginx service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;configuration successfully modified`,
  'stack_nginxConfig_ERROR': `Failed to modify nginx service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;configuration`,
  'stack_sharedStorageConnect_ERROR': `Failed to establish shared storage connection for service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>`,
  'stack_sharedStorageDisconnect_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;shared storage <strong>disconnected</strong>`,
  'stack_sharedStorageDisconnect_ERROR': `Failed to disconnect {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;shared storage`,
  'stack_delete_disconnectSharedStorageConnections_SUCCESS': `Storage service was disconnected entirely with success
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>`,
  'stack_delete_disconnectSharedStorageConnections_ERROR': `Complete storage service disconnection failed
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>`,
  'stack_upgrade_SUCCESS': `Failed to upgrade {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>`,
  'publicIpRequest_request_SUCCESS': `<strong>Unique IPv4</strong> add-on activated, IPv4 address successfully assigned to
  project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'publicIpRequest_request_ERROR': `Failed to activate <strong>Unique IPv4</strong> add-on on
  project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'publicIpRequest_accept_SUCCESS': `<strong>Unique IPv4</strong> add-on activated, IPv4 address successfully assigned to
  project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'publicIpRequest_accept_ERROR': `Failed to activate <strong>Unique IPv4</strong> add-on on
  project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'publicIpRequest_reject_WARNING': `Project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;IPv4 request <strong>rejected</strong>`,
  'publicIpRequest_release_SUCCESS': `<strong>Unique IPv4</strong> address was removed from the
  project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'stack_build_SUCCESS': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;build <strong>completed</strong>`,
  'stack_build_ERROR': `Failed to build {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a><span class="c-notification-item-fail">`,
  'stack_build_WARNING': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;build <strong>ended with warning</strong>`,
  'APPLICATION_EVENT_PORT_CHECK_FAILED_ERROR': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;not listening to specified ports`,
  'stack_updateObjectStorageSize_SUCCESS': `{serviceType} service
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp; settings successfully updated`,
  'stack_updateObjectStorageSize_ERROR': `Failed to update {serviceType} service
  <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;settings`,
  'APPLICATION_EVENT_FAIL_AND_RESTART_ERROR': `{serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
  {serviceName}</strong></a>&nbsp;app failed`,
  'zbilling_addonInsufficientFunds_ERROR': `Insufficient funds for add-on purchase`,
  'zbilling_topup_SUCCESS': `Your credit was successfully increased`,
  'zbilling_topup_ERROR': `Failed to increase your credit`,
  'zbilling_addonCountExceeded_ERROR': `Permitted number of add-ons was exceeded`,
  'zbilling_projectMandatoryMissingStop_ERROR': `Project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;is stopping`,
  'zbilling_projectMandatoryMissing_WARNING': ``,
  'zbilling_paymentCardExpired_ERROR': `Your card has expired`,
  'zbilling_paymentCardExpiring_SUCCESS': `Your card is expiring this month`,
  'zbilling_paymentCardNotFound_ERROR': `Your card was not found`,
  'zbilling_projectStop_WARNING': `Project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;is stopping`,
  'zbilling_projectStart_SUCCESS': `Project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
  {projectName}</a>&nbsp;was started`,
  'zbilling_subscriptionDeactivated_SUCCESS': `Add-on was deactivated on project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'zbilling_subscriptionDeactivated_WARNING': `Add-on was deactivated on project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'zbilling_subscriptionDeactivated_ERROR': `Failed to deactivate add-on on project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'zbilling_subscriptionActivated_SUCCESS': `Add-on was successfully activated on the project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'zbilling_subscriptionRegistered_SUCCESS': `Add-on was successfully activated on the project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'zbilling_subscriptionRenewalFailed_ERROR': `Failed to renew add-on on project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'zbilling_subscriptionRenewalSuccess_SUCCESS': `Add-on was successfully renewed on the project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>`,
  'zbilling_projectShutdownNotification_WARNING': `Your credits are running low. Please top up your credits, otherwise projects will begin automatically shutting down in less than <strong>{metadataLtsLessThanDays} days</strong>.`,
  'dnsCheckFailed_ERROR': 'DNS check failed'
};

export type NotificationItemTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class NotificationItemTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
