import { Addon, MetricIds, PaymentSource } from '@zerops/models/billing';

export class BillingBaseState {
  activePaymentRequest: {
    amount: number;
    clientSecret: string;
    paymentId: string;
    paymentStatus: string;
  };
  paymentSources: PaymentSource[];
  availableProjectAddons: Addon[];
  availableClientAddons: Addon[];

  constructor() {
    this.activePaymentRequest = undefined;
    this.paymentSources = undefined;
    this.availableProjectAddons = [];
    this.availableClientAddons = [];
  }
}

export const PRICELIST_MAP = {
  [MetricIds.ProjectCore]: 5,
  [MetricIds.ProjectIpV4]: 3
};
