/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './process-item.constant';

const en = {
  byUser: ' <span class="__by">by</span> {name}',
  byZerops: ' <span class="__by">by</span> <span class="__zerops">ZEROPS</span> {name}',
  to: 'to ',
  in: 'in ',
  from: 'from ',
  running: {
    'instance_create': `Creating project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a> and its core service <span class="__desc">It provides secure data communication between the Internet and your project (firewall built-in),
    storage for application logs, and technical statistics. It's managed automatically by Zerops.</span>`,
    'instance_delete': 'Deleting project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> and its services',
    'instance_stop': 'Stopping project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> and its services',
    'instance_stopPrivate': 'Stopping project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> and its services',
    'instance_start': 'Starting project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> and its services',
    'instance_startPrivate': 'Starting project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> and its services',
    'instance_syncPublicPortRouting': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> public access through IP addresses synchronization',
    'instance_syncPublicHttpRouting': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> public access through domains synchronization',
    'instance_syncPublicHttpRoutingAndCreateL7HttpBalancerStack': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> HTTP balancer creation and synchronization',
    'instance_createL7HttpBalancerStack': `Initializing project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> Routing service <span class="__desc">HTTP balancers handle HTTP/S data traffic,
    including SSL certificates, whether through Zerops subdomains or your own domains.</span>`,
    'instance_deleteL7HttpBalancerStack': 'Deleting project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a> Routing service',
    'instanceGroup_create': 'Initializing project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>',
    'instanceGroup_delete': 'Deleting project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>',
    'stack_create': 'Initializing & installing {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_delete': '<strong>Deleting</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_start': '<strong>Starting</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_stop': '<strong>Stopping</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_restart': `<strong>Restarting</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>`,
    'stack_reload': '<strong>Reloading</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_deploy': '<strong>Deploying</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_updateUserData': `Committing {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a> environment variables
    <span class="__desc">Restart will be required afterwards to apply.</span>`,
    'stack_disableSubdomainAccess': '<strong>Disabling</strong> Zerops <strong>subdomain</strong> on {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_enableSubdomainAccess': '<strong>Enabling</strong> Zerops <strong>subdomain</strong> on {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_updatePorts': '<strong>Updating internal ports</strong> on {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_update': 'Updating {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_upgrade': '<strong>Upgrading</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_sharedStorageConnect': 'Connecting {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a> with shared a storage',
    'stack_sharedStorageDisconnect': 'Disconnecting {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a> from a shared storage',
    'stack_nginxConfig': 'Modifying {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a> nginx configuration',
    'stack_build': 'Build and deploy in progress',
    'stack_createBuildStack': `Creating build service <span class="__desc">
      The build service will take care of the whole build process and the commissioning of your service.</span>`,
    'stack_deleteBuildStack': 'Deleting build service',
    'publicIpRequest_accept': 'Assigning unique IPv4 address to project',
    'stack_delete.disconnectSharedStorageConnections': 'Disconnecting all services',
    'stack_updateAutoscaling': 'Updating autoscaling of {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'publicIpRequest_request': 'Activating unique IPv4 add-on on project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>',
    'stack_updateObjectStorageSize': `Updating {serviceType} service
    <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
    {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
    {serviceName}</strong></a>&nbsp;settings`
  },
  pending: {
    'instance_create': `Project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a> core service creation <span class="__desc">The project core service
    takes care of secure data communication between the Internet and your project and of storage for
    application logs and technical statistics.</span>`,
    'instance_delete': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> deletion',
    'instance_start': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> start',
    'instance_startPrivate': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> start',
    'instance_stop': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> stop',
    'instance_stopPrivate': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> stop',
    'instance_syncPublicPortRouting': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> public access through IP addresses synchronization',
    'instance_syncPublicHttpRouting': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a>public access through domains synchronization',
    'instance_syncPublicHttpRoutingAndCreateL7HttpBalancerStack': 'Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> HTTP balancer creation and public access through domains synchronization',
    'instance_createL7HttpBalancerStack': `Project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> Routing service creation <span class="__desc">HTTP balancers handle HTTP/S data traffic, including SSL certificates, whether through Zerops subdomains or your own domains.</span>`,
    'instance_deleteL7HttpBalancerStack': 'Deletion of project <a class="c-internal-link" data-zui-router-link="/projects/{projectId}">{projectName}</a> Routing service',
    'instanceGroup_create': 'Project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a> instance initialization',
    'instanceGroup_delete': '<strong>Delete</strong> project <strong>{projectName}</strong>',
    'stack_create': 'Initialize & install {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_start': '<strong>Start</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_delete': '<strong>Delete</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_stop': '<strong>Stop</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_restart': '<strong>Restart</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_reload': '<strong>Reload</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_upgrade': '<strong>Upgrade</strong> {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_deploy': '<strong>Deploy</strong> new app version to {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_updateUserData': 'Commit {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a> environment variables',
    'stack_disableSubdomainAccess': '<strong>Disable</strong> Zerops <strong>subdomain</strong> on service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_enableSubdomainAccess': '<strong>Enable</strong> Zerops <strong>subdomain</strong> on service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_updatePorts': '<strong>Update internal ports</strong> on {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_update': 'Update {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'stack_sharedStorageConnect': 'Connect {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a> with a shared storage',
    'stack_sharedStorageDisconnect': 'Disconnect {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a> from a shared storage',
    'stack_nginxConfig': '{serviceType} service service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a> nginx configuration modify',
    'stack_build': 'Build and deploy in queue',
    'stack_createBuildStack': `Create build service in queue <span class="__desc">
      The build service takes care of the whole build process and the commissioning of your service.</span>`,
    'stack_deleteBuildStack': 'Delete build service in queue',
    'publicIpRequest_accept': 'Assign unique IPv4 address to project in queue',
    'stack_delete.disconnectSharedStorageConnections': 'Disconnect all services in queue',
    'stack_updateAutoscaling': 'Update autoscaling of {serviceType} service <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>{serviceName}</strong></a>',
    'publicIpRequest_request': 'Activate unique IPv4 add-on on project <a class="c-internal-link" data-zui-router-link="/project/{projectId}">{projectName}</a>',
    'stack_updateObjectStorageSize': `Update {serviceType}
    <a class="c-internal-link" data-zui-router-link="/project/{projectId}">
    {projectName}</a>/<a class="c-internal-link" data-zui-router-link="/service-stack/{serviceId}"><strong>
    {serviceName}</strong></a>&nbsp;settings`
  }
};

export type ProcessItemTranslations = typeof en;

@NgModule()
export class ProcessItemTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
