import { createAction, props, union } from '@ngrx/store';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import { Project } from '@zerops/models/project';
import { Process } from '@zerops/models/process';
import {
  RemoteLoggingDataPayload,
  VpnPublicKeyPayload,
  VpnSettings,
  VpnSetting
} from './project-base.model';

export const loadProjectTags = createAction(
  '[@zerops/zerops/project-base] load-project-tags',
  zefActionPayload<string>()
);

export const loadProjectTagsFail = createAction(
  '[@zerops/zerops/project-base] load-project-tags/fail',
  zefActionFailPayload
);

export const loadProjectTagsSuccess = createAction(
  '[@zerops/zerops/project-base] load-project-tags/success',
  zefActionSuccessPayload<string[]>()
);

export const requestIPv4 = createAction(
  '[@zerops/zerops/core/project-base] request-ipv4',
  zefActionPayload<string>()
);

export const requestIPv4Fail = createAction(
  '[@zerops/zerops/core/project-base] request-ipv4/fail',
  zefActionFailPayload
);

export const requestIPv4Success = createAction(
  '[@zerops/zerops/core/project-base] request-ipv4/success',
  zefActionSuccessPayload<Process>()
);

export const startProject = createAction(
  '[@zerops/zerops/core/project-base] start-project',
  zefActionPayload<{ id: string; }>()
);

export const startProjectFail = createAction(
  '[@zerops/zerops/core/project-base] start-project/fail',
  zefActionFailPayload
);

export const startProjectSuccess = createAction(
  '[@zerops/zerops/core/project-base] start-project/success',
  zefActionSuccessPayload<Project>()
);

export const projectExport = createAction(
  '[@zerops/zerops/core/project-base] project-export',
  zefActionPayload<string>()
);

export const projectExportFail = createAction(
  '[@zerops/zerops/core/project-base] project-export/fail',
  zefActionFailPayload
);

export const projectExportSuccess = createAction(
  '[@zerops/zerops/core/project-base] project-export/success',
  zefActionSuccessPayload<string>()
);

export const queueRecipeImport = createAction(
  '[@zerops/zerops/core/project-base] queue-project-import',
  props<{ nonHaRecipeId?: string; haRecipeId?: string; }>()
);

export const projectImport = createAction(
  '[@zerops/zerops/core/project-base] project-import',
  zefActionPayload<string>()
);

export const projectImportFail = createAction(
  '[@zerops/zerops/core/project-base] project-import/fail',
  zefActionFailPayload
);

export const projectImportSuccess = createAction(
  '[@zerops/zerops/core/project-base] project-import/success',
  zefActionSuccessPayload()
);

export const updateRemoteLogging = createAction(
  '[@zerops/zerops/core/project-base] update-remote-logging',
  zefActionPayload<{
    id: string;
    payload: RemoteLoggingDataPayload;
  }>()
);

export const updateRemoteLoggingFail = createAction(
  '[@zerops/zerops/core/project-base] update-remote-logging/fail',
  zefActionFailPayload
);

export const updateRemoteLoggingSuccess = createAction(
  '[@zerops/zerops/core/project-base] update-remote-logging/success',
  zefActionSuccessPayload<Process>()
);

/**
 * Register a new VPN public key.
 */
export const addVpnPublicKey = createAction(
  '[@zerops/zerops/core/project-base] add-vpn-public-key',
  zefActionPayload<{
    id: string;
    payload: VpnPublicKeyPayload;
  }>()
);

export const addVpnPublicKeySuccess = createAction(
  '[@zerops/zerops/core/project-base] add-vpn-public-key/success',
  zefActionSuccessPayload<VpnSetting>()
);

export const addVpnPublicKeyFail = createAction(
  '[@zerops/zerops/core/project-base] add-vpn-public-key/fail',
  zefActionFailPayload
);

/**
 * Delete an already registered VPN public key.
 */
export const deleteVpnPublicKey = createAction(
  '[@zerops/zerops/core/project-base] delete-vpn-public-key',
  zefActionPayload<{
    id: string;
    payload: VpnPublicKeyPayload;
  }>()
);

export const deleteVpnPublicKeySuccess = createAction(
  '[@zerops/zerops/core/project-base] delete-vpn-public-key/success',
  zefActionSuccessPayload<{ success: true }>()
);

export const deleteVpnPublicKeyFail = createAction(
  '[@zerops/zerops/core/project-base] delete-vpn-public-key/fail',
  zefActionFailPayload
);

/**
 * List of all already registered VPN public keys.
 */
export const listVpnPublicKeys = createAction(
  '[@zerops/zerops/core/project-base] list-vpn-public-keys',
  zefActionPayload<{
    id: string;
  }>()
);

export const listVpnPublicKeysSuccess = createAction(
  '[@zerops/zerops/core/project-base] list-vpn-public-keys/success',
  zefActionSuccessPayload<{ id: string, response: VpnSettings }>()
);

export const listVpnPublicKeysFail = createAction(
  '[@zerops/zerops/core/project-base] list-vpn-public-keys/fail',
  zefActionFailPayload
);

const all = union({
  loadProjectTags,
  loadProjectTagsFail,
  loadProjectTagsSuccess,
  requestIPv4,
  requestIPv4Fail,
  requestIPv4Success,
  startProject,
  startProjectFail,
  startProjectSuccess,
  projectExport,
  projectExportFail,
  projectExportSuccess,
  projectImport,
  projectImportSuccess,
  projectImportFail,
  updateRemoteLogging,
  updateRemoteLoggingSuccess,
  updateRemoteLoggingFail,
  addVpnPublicKey,
  addVpnPublicKeySuccess,
  addVpnPublicKeyFail,
  deleteVpnPublicKey,
  deleteVpnPublicKeySuccess,
  deleteVpnPublicKeyFail,
  listVpnPublicKeys,
  listVpnPublicKeysSuccess,
  listVpnPublicKeysFail
});

export type ProjectBaseActionUnion = typeof all;
