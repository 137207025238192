export const ANGULAR_IMPORT_YAML = `project:
  name: zerops-angular
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: nodejs@20
    ports:
      - port: 3000
        httpSupport: true
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-onboarding-nodejs`;
