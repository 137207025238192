import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { formValueOnValid } from '@zerops/zef/forms';
import { ObservableInput } from 'observable-input';
import { merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@zerops/zerops/app';
import {
  integrateExternalRepository,
  removeExternalRepositoryIntegration,
  ServiceStackEntity
} from '@zerops/zerops/core/service-stack-base';
import { RemoteRepositoryConnectorGithubForm } from './remote-repository-connector-github.form';
import { RemoteRepositoryConnectorGitlabForm } from './remote-repository-connector-gitlab.form';
import { selectZefProgressByType } from '@zerops/zef/progress';
import { ServiceStackStatuses } from '@zerops/models/service-stack';
import { ProcessEntity } from '@zerops/zerops/core/process-base';
import { ProcessActions } from '@zerops/models/process';

@Component({
  selector: 'z-remote-repository-connector',
  templateUrl: './remote-repository-connector.feature.html',
  styleUrls: [ './remote-repository-connector.feature.scss' ]
})
export class RemoteRepositoryConnectorFeature extends ZefReactiveComponent {

  // # Event Streams
  onIntegrateExternalRepository$ = new Subject<'github' | 'gitlab'>();
  onRemoveExternalRepositoryIntegration$ = new Subject<void>();

  // # Forms
  githubFormState$ = this._githubForm.state$;
  gitlabFormState$ = this._gitlabForm.state$;

  // # Data
  // -- sync
  integrateExternalRepositoryKey = integrateExternalRepository.type;
  removeExternalRepositoryIntegrationKey = removeExternalRepositoryIntegration.type;
  serviceStackStates = ServiceStackStatuses;

  // - angular
  @ObservableInput()
  @Input('serviceStackId')
  serviceStackId$!: Observable<string>;

  // -- async
  onIntegrateGitlab$ = this.onIntegrateExternalRepository$.pipe(filter((t) => t === 'gitlab'));
  onIntegrateGithub$ = this.onIntegrateExternalRepository$.pipe(filter((t) => t === 'github'));
  serviceStack$ = this.serviceStackId$.pipe(
    filter((d) => !!d),
    switchMap((id) => this._serviceStackEntity.entityById$(id)),
    filter((d) => !!d)
  );
  serviceId$ = this.serviceStack$.pipe(
    map(({ id }) => id)
  );
  processesMap$ = this._processEntity.processMap$().pipe(
    withLatestFrom(this.serviceId$),
    map(([ d, serviceId ]) => d?.serviceMap?.[serviceId])
  );
  hasBuildProcess$ = this.processesMap$.pipe(
    map((d) => d?.running.some((process) => process.actionName === ProcessActions.stackBuild)
      || d?.pending.some((process) => process.actionName === ProcessActions.stackBuild)
    )
  );

  removeExternalLoadingState$ = this._store.pipe(
    select(selectZefProgressByType(this.removeExternalRepositoryIntegrationKey)),
    map((d) => !!d)
  );

  // # State resolver
  state = this.$connect({
    githubFormState: this.githubFormState$,
    gitlabFormState: this.gitlabFormState$,
    serviceStack: this.serviceStack$,
    removeExternalLoadingState: this.removeExternalLoadingState$,
    hasBuildProcess: this.hasBuildProcess$
  });

  private _formResetActions = [
    this._githubForm.reset(),
    this._githubForm.setDefaultValues(),
    this._gitlabForm.reset(),
    this._gitlabForm.setDefaultValues()
  ];

  // # Action Streams
  private _integrateGithubAction$ = this.onIntegrateGithub$.pipe(
    formValueOnValid(this._githubForm),
    withLatestFrom(this.serviceStackId$.pipe(filter((d) => !!d))),
    map(([ gitHubIntegration, id ]) => integrateExternalRepository({ id, gitHubIntegration }))
  );

  private _integrateGitlabAction$ = this.onIntegrateGitlab$.pipe(
    formValueOnValid(this._gitlabForm),
    withLatestFrom(this.serviceStackId$.pipe(filter((d) => !!d))),
    map(([ gitLabIntegration, id ]) => integrateExternalRepository({ id, gitLabIntegration }))
  );
  private _onInitResetFormsAction$ = merge(
    this.onInit$,
    this.serviceStackId$.pipe(
      distinctUntilChanged(),
      skip(1)
    )
  ).pipe(switchMap(() => this._formResetActions));

  private _removeExternalRepositoryIntegrationAction$ = this.onRemoveExternalRepositoryIntegration$.pipe(
    withLatestFrom(this.serviceStackId$),
    switchMap(([ _, id ]) => [
      removeExternalRepositoryIntegration({ id }),
      ...this._formResetActions
    ])
  );

  constructor(
    private _store: Store<AppState>,
    private _githubForm: RemoteRepositoryConnectorGithubForm,
    private _gitlabForm: RemoteRepositoryConnectorGitlabForm,
    private _serviceStackEntity: ServiceStackEntity,
    private _processEntity: ProcessEntity
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._integrateGithubAction$,
      this._integrateGitlabAction$,
      this._removeExternalRepositoryIntegrationAction$,
      this._onInitResetFormsAction$
    ]);
  }

}
