<mat-card class="__card  z-manual-elevation  c-soft-elevation">
  <ng-container *ngFor="let item of data; trackBy: trackByIndex;">

    <ng-container *ngIf="item.originalError?.status === 403">
      <div class="__content">

        <h3 class="__title">You have been logged out</h3>

        <p class="__desc">Your session expired and we weren't able to refresh it, please login again.</p>

        <button
          mat-stroked-button
          type="button"
          (click)="onLogout$.next()"
          class="c-button--full  c-button--larger">

          Go to login

          <mat-icon>navigate_next</mat-icon>

        </button>

      </div>
    </ng-container>

    <ng-container *ngIf="item.key === userLoadKey">
      <div class="__content">

        <div class="__icon-wrap">
          <mat-icon class="__icon">sentiment_dissatisfied</mat-icon>
        </div>

        <h3 class="__title">
          Couldn't load vital parts of the application
        </h3>

        <p class="__desc">
          Our servers are most likely down. And we are terribly sorry about that. Please try again later, or contact our support.
        </p>

        <a
          href=""
          class="c-button--full  __button"
          mat-stroked-button>
          Try again now.
        </a>

        <zui-separator [size]="'small'" [spacing]="'mini'" />

        <div class="fx:grid  fx:grid-cols-1  fx:col-gap-3  __buttons">

          <div class="fx:pb-0-5">
            <a
              mat-flat-button
              [href]="'https://discord.com/invite/WDvCZ54'"
              target="_blank"
              class="__discord-button  fx:w-100    c-button--smaller">
              <mat-icon svgIcon="discord"></mat-icon>
                Join our Discord server
            </a>
          </div>

          <div class="fx:pb-0-5">
            <a
              [href]="'https://support.zerops.io'"
              target="_blank"
              mat-flat-button
              class="__discourse-button  fx:w-100  c-button--smaller">
              <mat-icon svgIcon="discourse"></mat-icon>
                Join our Support forum
            </a>
          </div>

          <div class="__iframe-wrap">
            <div class="__iframe  fx:center">
              <iframe
                src="https://status.zerops.io/badge?theme=light"
                width="250"
                height="30"
                frameborder="0"
                scrolling="no">
              </iframe>
            </div>
          </div>

        </div>

      </div>
    </ng-container>

    <ng-container *ngIf="item.originalError?.status !== 403 && item.key !== userLoadKey">
      <div class="__content">
        Oops: {{ item?.message }}
      </div>
    </ng-container>

  </ng-container>
</mat-card>
