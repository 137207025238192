import { Subscription } from '@zerops/models/subscription';
import { Addon } from '@zerops/models/billing';
import { Project } from '@zerops/models/project';
import { GroupedAddon } from '@zerops/models/billing';
import groupBy from 'lodash-es/groupBy';
import orderBy from 'lodash-es/orderBy';

export const groupAddons = (
  subscriptions: Subscription[],
  availableAddons: Addon[],
  projects: Project[],
  projectId?: string,
  onlyGroupsWithActiveAddon = false
) => {
  const data = [ ...subscriptions || [], ...availableAddons || [] ];
  const uniqueMetricIds = Object.keys(groupBy(data, 'metricId'));
  const arr = [];
  uniqueMetricIds.forEach((key) => {
    if (!arr[key]) {
      arr.push({
        metricId: key,
        subscriptions: orderBy(subscriptions?.filter((d) => d.metricId === key).map((dd) => {
          const project = projects?.find((p) => p.id === dd.projectId);
          return {
            ...dd,
            projectName: project?.name
          };
        }), 'projectName'),
        availableAddons: orderBy(availableAddons?.filter((d) => d.metricId === key).map((dd) => {
          const project = projects?.find((p) => p.id === dd.projectId);
          return {
            ...dd,
            projectName: project?.name
          };
        }), 'projectName')
      });
    }
  });

  if (projectId) {
    return orderBy(arr.map((group) => ({
      ...group,
      availableAddons: group.availableAddons?.filter((addon) => addon.projectId === projectId),
      subscriptions: group.subscriptions?.filter((subscription) => subscription.projectId === projectId)
    })), 'metricId') as GroupedAddon[];
  }

  return orderBy(
    onlyGroupsWithActiveAddon
      ? arr.filter((d) => d.subscriptions?.length).map((d) => ({ ...d, availableAddons: [] }))
      : arr, 'metricId'
  ) as GroupedAddon[];
}
