<!--
  TODO:
  https://github.com/angular/angular/issues/36222
  contain <router-outlet> as well when issue resolved
-->
<div class="__app-bar-wrap" [class.is-compact]="state.isCompact">
  <ng-template [ngIf]="state.authState !== authStates.Checking && state.activeClientUser">
    <z-app-bar
      [active]="state.authState === this.authStates.Authorized"
      [logoTheme]="menuPanelOpen ? 'white' : 'black'"
      [menuState]="menuPanelOpen"
      class="__app-bar">
    </z-app-bar>
  </ng-template>

</div>

<div class="u-position--relative">
  <div
    [@outerRouteAnimation]="prepareRoute(outlet)"
    class="__animation-wrap">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

<z-pop-error
  *zefPopErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance">
</z-pop-error>

<zui-snack-error
  *zefSnackErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance">
</zui-snack-error>

<zui-snack-success
  *zefSnackOutlet="let data = data; let instance = instance; type: 'success'"
  [data]="data"
  [instance]="instance">
</zui-snack-success>

<zui-snack-warning
  *zefSnackOutlet="let data = data; let instance = instance; type: 'warning'"
  [data]="data"
  [instance]="instance">
</zui-snack-warning>

<zui-snack-info
  *zefSnackOutlet="let data = data; let instance = instance; type: 'info'"
  [data]="data"
  [instance]="instance">
</zui-snack-info>

<z-dialog-error
  *zefDialogErrorOutlet="let data = data"
  [data]="data">
</z-dialog-error>

<z-notification></z-notification>

<zef-dialog-error></zef-dialog-error>

<z-version-warning *ngIf="isProduction && !hideVersionWarning"></z-version-warning>

<zef-recaptcha [siteKey]="googleSiteKey"></zef-recaptcha>


<z-pipeline-detail-dialog></z-pipeline-detail-dialog>

<z-deletion-warning-dialog></z-deletion-warning-dialog>

<z-http-routing-add-dialog></z-http-routing-add-dialog>

<z-http-routing-edit-dialog></z-http-routing-edit-dialog>

<z-port-routing-add-dialog></z-port-routing-add-dialog>

<z-project-cost-limit-dialog></z-project-cost-limit-dialog>

<z-port-routing-edit-dialog></z-port-routing-edit-dialog>

<z-user-data-add-dialog></z-user-data-add-dialog>

<z-user-data-edit-dialog></z-user-data-edit-dialog>

<z-env-format-dialog></z-env-format-dialog>

<z-copy-code-dialog></z-copy-code-dialog>

<z-import-export-dialog></z-import-export-dialog>

<z-deploy-shutdown-dialog></z-deploy-shutdown-dialog>

<z-addon-activation-dialog></z-addon-activation-dialog>

<z-log-dialog></z-log-dialog>

<z-sync-dialog></z-sync-dialog>

<z-billing-info-edit-dialog></z-billing-info-edit-dialog>

<z-notifications-pop></z-notifications-pop>

<z-processes-pop></z-processes-pop>

<z-top-up-dialog></z-top-up-dialog>

<zui-contextual-dialog
  [documentationPath]="documentationPath">
</zui-contextual-dialog>

<zui-web-notifications-pop></zui-web-notifications-pop>

<zui-web-public-routing-pop></zui-web-public-routing-pop>

<zui-web-service-stack-active-app-version-pop></zui-web-service-stack-active-app-version-pop>

<z-new-service-dialog></z-new-service-dialog>

<zui-docs-pop [documentationPath]="documentationPath"></zui-docs-pop>

<ng-container *ngIf="state.authState === this.authStates.Authorized">
  <z-ws-reconnect
    *ngIf="state.wsReconnectShow"
    class="__ws-reconnect"
    [state]="state.wsReconnectState"
    [translations]="state.wsReconnectTranslations">
  </z-ws-reconnect>
</ng-container>

<z-websocket-debugger></z-websocket-debugger>
