<mat-card
  class="__card  c-addon-card-card"
  [class.is-project-mode-and-active]="mode === modes.Project && subscriptions?.length && !availableAddons?.length">

  <h3 class="__name">{{ generalTranslations.addonName[metricId] }} <div class="__price">${{ addonPrice }} / 30 days</div></h3>

  <!-- addon features -->
  <ul class="__list  c-addon-card-list">
    <ng-template [ngTemplateOutlet]="listContentRef"></ng-template>
  </ul>

  <ng-template #listContentRef>

    <ng-container *ngIf="metricId === metricIds.ProjectCore">
      <li>Unique IPv6 address for the project</li>
      <li>Logger service, statistics service, HTTP routing with SSL certificates, IP routing with built-in firewall</li>
      <li>Free and fully managed load balancers and proxies for all your database services</li>
      <li>Free build time on performant build containers for all your runtime services</li>
      <li class="is-last">Daily backup of services in a data center situated in a geographically different location</li>
    </ng-container>

    <ng-container *ngIf="metricId === metricIds.ProjectIpV4">
      <li class="__list-text">
        Assigns a unique IPv4 address to the project. This eliminates the risk of a shared IPv4 address being blacklisted due to abuse by another user. This also enables IP routing with firewall through the assigned IPv4 address.
      </li>
    </ng-container>

  </ng-template>

  <zui-separator
    [size]="'small'"
    [spacing]="'small'">
  </zui-separator>

  <!-- single item -->
  <ng-container *ngIf="!!subscriptionData || !!addonData">

    <!-- subscription -->
    <ng-container *ngIf="!!subscriptionData">

      <span
        *ngIf="subscriptionData.recurringEnabled"
        class="__text">
        Deactivate autorenew of <strong>{{ generalTranslations.addonName[metricId] }}</strong> addon on <strong class="__project-name">{{ subscriptionData.projectName }}</strong> project.
        Valid from {{ subscriptionData.validFrom | date: 'mediumDate' }} to {{ subscriptionData.validTill | date: 'mediumDate' }} (renews on {{ subscriptionData.validTill | date: 'mediumDate' }}).
      </span>

      <span
        *ngIf="!subscriptionData.recurringEnabled"
        class="__text">
        Activate autorenew of <strong>{{ generalTranslations.addonName[metricId] }}</strong> addon on <strong class="__project-name">{{ subscriptionData.projectName }}</strong> project.
        Valid from {{ subscriptionData.validFrom | date: 'mediumDate' }} to {{ subscriptionData.validTill | date: 'mediumDate' }}.
      </span>

    </ng-container>

    <!-- addon -->
    <span
      *ngIf="!!addonData"
      class="__text">
      Activate <strong>{{ generalTranslations.addonName[metricId] }}</strong> addon on <strong class="__project-name">{{ addonData.projectName }}</strong> project for
      <strong>${{ addonData.unitPrice }}/30 days</strong> from {{ addonData.validFrom | date: 'mediumDate' }} to {{ addonData.validTill | date: 'mediumDate' }} (renews on {{ addonData.validTill | date: 'mediumDate' }}).
    </span>

  </ng-container>

  <ng-container *ngIf="!subscriptionData && !addonData">

    <!-- subscriptions -->
    <div
      *ngFor="let item of subscriptions; let last = last; trackBy: trackByIndex"
      class="__item-grid is-active"
      [class.is-last]="last && !availableAddons?.length"
      [class.is-project-mode]="mode === modes.Project">
      <ng-template
        [ngTemplateOutlet]="projectInfoRef"
        [ngTemplateOutletContext]="{ item, type: 'subscription' }">
      </ng-template>
    </div>

    <!-- addons -->
    <div
      *ngFor="let item of availableAddons; let last = last; trackBy: trackByIndex"
      class="__item-grid"
      [class.is-last]="last"
      [class.is-project-mode]="mode === modes.Project">
      <ng-template
        [ngTemplateOutlet]="projectInfoRef"
        [ngTemplateOutletContext]="{ item, type: 'addon' }">
      </ng-template>
    </div>

  </ng-container>

</mat-card>

<ng-template #projectInfoRef let-item="item" let-type="type">

  <zui-linked-basic-info-header
    *ngIf="mode !== modes.Project"
    [link]="[ '/project', item.projectId, 'finances' ]"
    [linkText]="'Go to ' + item.projectName + ' add-ons.'"
    [linkIcon]="'arrow_forward'"
    [linkIconPosition]="'end'"
    [size]="infoHeaderSize"
    (linkClicked)="linkClicked.emit()">
    <zui-project-basic-info
      [name]="item.projectName"
      [nameSize]="infoHeaderSize">
    </zui-project-basic-info>
  </zui-linked-basic-info-header>

  <div class="__right-column">

    <zui-addon-button
      *ngIf="metricId !== metricIds.ProjectCore && mode === modes.Project"
      [addon]="type === 'addon' ? item : undefined"
      [subscription]="type === 'subscription' ? item : undefined"
      (buttonClick)="buttonClick.emit($event)">
    </zui-addon-button>

    <span
      *ngIf="metricId === metricIds.ProjectCore || mode === modes.Info"
      class="__valid-date"
      [class.is-project-mode]="mode === modes.Project">
      <mat-icon
        *ngIf="type === 'subscription' && item.recurringEnabled"
        class="__renews-icon"
        [matTooltip]="'Renews on ' + (item.validTill | date: 'shortDate')">
        autorenew
      </mat-icon>
      <ng-container *ngIf="metricId === metricIds.ProjectCore && mode !== modes.Info">
        Active from {{ item.validFrom | date: 'shortDate' }} to {{ item.validTill | date: 'shortDate' }}
        <span class="__removal-info">Delete project to deactivate addon</span>
      </ng-container>
      <ng-container *ngIf="mode === modes.Info">{{ type === 'addon' ?  'Activate' : 'Active' }} from {{ item.validFrom | date: 'shortDate' }} to {{ item.validTill | date: 'shortDate' }}</ng-container>
    </span>

  </div>

</ng-template>
