import { Pipe, PipeTransform } from '@angular/core';

const countDecimals = function (value: number) {
  if(Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

const round = (x: number, n: number) => Number(Number(x).toFixed(n));

@Pipe({
  name: 'decimals',
  pure: true
})
export class DecimalsPipe implements PipeTransform {
  transform(number: number, decimalCount = 2, minDecimalCount?: number) {

    if (number === undefined || number === null) { return undefined; }
    const decCount = countDecimals(round(number, minDecimalCount || decimalCount));

    if (Math.floor(number) !== parseInt(round(number, minDecimalCount || decCount)?.toString()?.split('.')?.[0], 10)) {
      return '9'.repeat(minDecimalCount || decCount);
    }

    if (decCount <= decimalCount) {
      if (decCount) {
        return number.toFixed(minDecimalCount ? minDecimalCount : decCount)?.toString().split('.')[1];
      } else {
        return number.toFixed(minDecimalCount ? minDecimalCount : 2)?.toString().split('.')[1];
      }
    } else {
      return number.toFixed(minDecimalCount ? minDecimalCount : decimalCount)?.toString().split('.')[1];
    }
  }
}
